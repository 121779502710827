import {
  type ClientLoaderFunctionArgs,
  Link,
  redirect,
} from '@remix-run/react';
import { $path } from 'remix-routes';

import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);

  const resp = await tokenWithRedirect(
    () => apiService.otp.queryLogs(),
    action.request.url
  );
  const logs = resp.data.logs;
  if (logs.length > 0) {
    const log = logs[0];

    const params = new URLSearchParams(url.searchParams);
    params.set('pack-id', log.objectId);
    params.set('price-id', log.priceId);
    params.set('trigger-upsell', 'true');

    return redirect($path('/checkout/completed', params));
  }

  return {};
}

export function Component() {
  return (
    <div className='fixed inset-0'>
      <div className='w-full h-full flex flex-col items-center justify-center'>
        <div className='text-white font-bold text-center'>
          You did not purchase any event. <br />
          Please make sure you are logged into the correct account.
        </div>
        <Link to='/home' className='underline text-primary mt-3'>
          Back to home
        </Link>
      </div>
    </div>
  );
}
